import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Dialog, DialogContent, DialogTitle, Grid, Tab } from "@mui/material";
import ArgonButton from "components/ArgonButton";

import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonTypography from "components/ArgonTypography";
import { Schedule } from "../../../api/BackendApi/Schedule";
import { useState } from "react";

function PauseScheduleDialog({ open, onClose, scheduleId }) {

  const [selectedTab, setSelectedTab] = useState("1");

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const onDateChange = (args) => {
    console.log(args)
    setDateRange(args);
  }

  const onNormalPause = async () => {
    await Schedule.pause(scheduleId, true);
    onClose();
  }

  const onPauseFromTo = async () => {
    await Schedule.pauseFromTo(scheduleId, dateRange[0], dateRange[1]);
    onClose();
  }

  const onTabChange = (event, value) => {
    setSelectedTab(value);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ "& .MuiDialog-paper": { width: "100%" } }
      }
      fullWidth={"md"}
      maxWidth={"md"}
    >
      <DialogTitle textAlign="center">{"Pause Schedule"}</DialogTitle>
      <DialogContent>
        <TabContext value={selectedTab}>
          <TabList sx={{ height: '2em' }} onChange={onTabChange} aria-label="lab API tabs example">
            <Tab label="Pause Indefenetly" value="1" />
            <Tab label="Pause from to" value="2" />
          </TabList>
          <TabPanel value="1" sx={{ width: '100%', typography: 'body1' }}>
            <ArgonTypography>Schedule will be paused until unpaused</ArgonTypography>
            <ArgonButton color="info" onClick={onNormalPause}>Pause</ArgonButton>
          </TabPanel>
          <TabPanel value="2" sx={{ width: '100%', typography: 'body1' }}>
            <Grid container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              columns={2}
              spacing={1}>
              <Grid item xs={1}>
                <ArgonDatePicker
                  onChange={onDateChange}
                  options={{
                    mode: "range",
                    altInput: true,
                    dateFormat: 'Z',
                    altFormat: "Y-m-d H:i",
                    enableTime: true,
                    defaultDate: dateRange,
                    minDate: new Date(),

                  }}
                />
              </Grid>
            </Grid>


            <ArgonTypography>Schedule will be paused from DATE to DATE</ArgonTypography>
            <ArgonButton color="info" onClick={onPauseFromTo}>Pause</ArgonButton>
          </TabPanel>
        </TabContext>

      </DialogContent>
    </Dialog >
  );
}

export default PauseScheduleDialog;