import Grid from "@mui/material/Grid";
import { CardActions, CardHeader, Divider, Icon } from '@mui/material';
import Card from "@mui/material/Card";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ExpandMore, InfoRounded, WarningRounded } from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { QueryClient, QueryClientProvider, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import ArgonBadge from "../../../components/ArgonBadge";
import { keyframes } from "@emotion/react";
import CardMedia from "@mui/material/CardMedia";
import ArgonButton from "../../../components/ArgonButton";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { enqueueSnackbar } from "notistack";
import { Locator } from "../../../api/BackendApi/Locator";
import Backdrop from "@mui/material/Backdrop";
import ArgonBox from "../../../components/ArgonBox";
import Modal from "@mui/material/Modal";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonInput from "../../../components/ArgonInput";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import ArgonSelect from "../../../components/ArgonSelect";
import { Alert } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadLocator from "./UploadLocator";


function PageLocatorsTable({ page, pagesLocator, setPagesLocator, isExpanded, loadPagesLocator }) {

    const [expanded, setExpanded] = useState(isExpanded);

    const [refreshCounter, setRefreshCounter] = useState(0);

    const [openSelfHealingSinglePage, setOpenSelfHealingSinglePage] = useState(false);
    const [openScraperSinglePage, setOpenScraperSinglePage] = useState(false);
    const [openCreateLocator, setOpenCreateLocator] = useState(false);
    const [openDeleteLocator, setOpenDeleteLocator] = useState(false);
    const [openDeletePage, setOpenDeletePage] = useState(false);

    const [errorPage, setErrorPage] = useState(null);
    const [isMonitoring, setIsMonitoring] = useState(false);
    const [monitoringTime, setMonitoringTime] = useState(null);

    const [editInformation, setEditInformation] = useState(false);

    const [nameLocator, setNameLocator] = useState("");
    const [xpathLocator, setXpathLocator] = useState("");
    const [xpathParentLocator, setXpathParentLocator] = useState(null);
    const [componentLocator, setComponentLocator] = useState(null);
    const [priorityLocator, setPriorityLocator] = useState(null);
    const [actionLocator, setActionLocator] = useState(null);

    const [deleteLocatorInfo, setDeleteLocatorInfo] = useState(null);

    const [namePageTitle, setNamePageTitle] = useState(page.page_title);
    const [newNamePageTitle, setNewNamePageTitle] = useState(null);

    const [namePage, setNamePage] = useState(page.page);
    const [newPage, setNewPage] = useState(null);

    const filterPage = useRef({ market: page.market, env: page.env, project: page.project, capability: page.capability, level: page.level });

    const [isTypingPageTitle, setIsTypingPageTitle] = useState(false);
    const [isTypingPage, setIsTypingPage] = useState(false);

    const [samePageDifferentMarket, setSamePageDifferentMarket] = useState(false);

    const [isCreatingLocator, setIsCreatingLocator] = useState(false);

    const mounted = useRef(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleOpenSelfHealingSinglePageModal = () => {
        setOpenSelfHealingSinglePage(true);
    }

    const handleOpenScraperSinglePageModal = async () => {
        const response = await Locator.checkSamePageDifferentMarket(namePageTitle, filterPage);
        if (response.status === 200) {
            if (response.data.check) {
                setSamePageDifferentMarket(true)
            }
        }
        setOpenScraperSinglePage(true);
    }

    const handleEditInformation = (locator) => {
        if (!editInformation) {
            setXpathParentLocator(locator.parent);
            if (locator.isComponent) {
                setComponentLocator(locator.typeComponent);
                setPriorityLocator(locator.priorityComponent);
                setActionLocator(locator.actionComponent);
            }
        } else {
            setXpathParentLocator(null);
            setComponentLocator(null);
            setPriorityLocator(null);
            setActionLocator(null);
        }
        setEditInformation(!editInformation);
    }

    const handleSaveNewInformation = async (locator) => {
        const response = await Locator.saveNewInformation(page, locator, xpathParentLocator, componentLocator, priorityLocator, actionLocator);
        if (response.status === 200) {
            await loadPagesLocator();
            enqueueSnackbar("Locator information updated!", { variant: "success", autoHideDuration: 2000 });
        }
        setXpathParentLocator(null);
        setComponentLocator(null);
        setPriorityLocator(null);
        setActionLocator(null);
        setEditInformation(!editInformation);
    }

    const handleOpenCreateLocatorModal = () => {
        setNameLocator("");
        setXpathLocator("");
        setXpathParentLocator(null);
        setComponentLocator(null);
        setPriorityLocator(null);
        setActionLocator(null);
        setOpenCreateLocator(true);
    }

    const handleOpenDeletePageModal = () => {
        setOpenDeletePage(true);
    }

    const handleCloseSelfHealingSinglePageModal = () => {
        setOpenSelfHealingSinglePage(false);
    }

    const handleCloseScraperSinglePageModal = () => {
        setSamePageDifferentMarket(false)
        setOpenScraperSinglePage(false);
    }

    const handleCloseCreateLocatorModal = () => {
        setOpenCreateLocator(false);
    }

    const handleCloseDeleteLocatorModal = () => {
        setOpenDeleteLocator(false);
    }

    const handleCloseDeletePageModal = () => {
        setOpenDeletePage(false);
    }

    const handleDeletePage = async () => {
        const response = await Locator.deleteLocatorPageOnDB(namePageTitle, namePage, filterPage.current.project, filterPage.current.capability, filterPage.current.market, filterPage.current.env);
        if (response.status === 200) {
            if (response.data.delete_page > 0) {
                enqueueSnackbar('Page deleted!', { variant: 'success' });
                handleCloseDeletePageModal();
                await loadPagesLocator();
            }
        } else {
            enqueueSnackbar('Error!', { variant: 'error' })
            handleCloseDeletePageModal();
        }
    };

    const handleDeleteErrorPage = async () => {
        const response = await Locator.deleteErrorPageOnDB(page);
        if (response.status === 200) {
            if (response.data.delete_error_page > 0) {
                enqueueSnackbar('Error Page deleted!', { variant: 'success' });
                setErrorPage(null);
                await loadPagesLocator();
            }
        } else {
            enqueueSnackbar('Error!', { variant: 'error' })
        }
    };

    const handleExportData = async () => {
        await Locator.downloadLocator(namePageTitle, filterPage.current.project, filterPage.current.capability, filterPage.current.market, filterPage.current.env).then((response) => {
            if (response.status === 200) {
                const blob = new Blob([JSON.stringify(response.data, null, 2)], { type: "application/json" });
                saveAs(blob, namePageTitle + "_" + filterPage.current.project + "_" + filterPage.current.capability + "_" + filterPage.current.market + "_" + filterPage.current.env + ".json");
            }
        })
    };

    const updateLocatorPageTitle = async () => {
        if (newNamePageTitle === "" || newNamePageTitle === namePageTitle) return;

        let resp = await Locator.updatePageTitle(page._id, newNamePageTitle, filterPage.current)

        if (resp.data.update_page > 0) {
            setNamePageTitle(newNamePageTitle);
            for (let i = 0; i < pagesLocator.length; i++) {
                if (pagesLocator[i]._id === page._id) {
                    pagesLocator[i].page_title = newNamePageTitle;
                    break;
                }
            }
            setPagesLocator(pagesLocator);
            enqueueSnackbar("Page Title updated", { variant: "success" });
        } else if (resp.data.update_page === -1) {
            setNamePageTitle(namePageTitle);
            enqueueSnackbar("Page Title already exists", { variant: "warning" });
        }
    }

    const updateLocatorPage = async () => {
        if (newPage.replace(/\s/g, "") === "" || newPage === namePage) return;

        let resp = await Locator.updatePage(page._id, newPage)
        if (resp.status === 200) {
            setNamePage(newPage);
            enqueueSnackbar("Page updated", { variant: "success" });
        }
    }

    const handleOpenDetailPanelLocatorModal = async (action, locator, xpath) => {
        try {
            switch (action) {
                case "delete":
                    let respDelete = await Locator.deleteSecondaryLocatorOnDB(page, locator, xpath);
                    if (respDelete.status === 200) {
                        enqueueSnackbar(locator + ": " + xpath + " deleted successfully!", { variant: "success" });
                        await refetch()
                        return Promise.resolve();
                    }
                    break;
                case "change":
                    let respChange = await Locator.changePrincipalLocatorOnDB(page, locator, xpath);
                    if (respChange.status === 200) {
                        enqueueSnackbar(locator + ": " + xpath + " is now the main locator!", { variant: "success" });
                        await refetch()
                        return Promise.resolve();
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {
            enqueueSnackbar("Error during the action!", { variant: "error" });
            return Promise.reject();
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 500,
        bgcolor: 'background.paper',
        border: '2px',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
    };

    //RUN SELF HEALING
    const handleRunSelfHealing = async () => {
        const response = await Locator.selfHealingLocatorPage(page);
        if (response.status === 200) {
            enqueueSnackbar('Self Healing Started!', { variant: 'success' });
            setOpenSelfHealingSinglePage(false);
        } else {
            enqueueSnackbar('Error!', { variant: 'error' })
            setOpenSelfHealingSinglePage(false);
        }
    }

    //RUN SCRAPER
    const handleRunScraper = async () => {
        const response = await Locator.scraperLocatorPage(page);
        if (response.status === 200) {
            enqueueSnackbar('Scraper Started!', { variant: 'success' });
            setOpenScraperSinglePage(false);
        } else {
            enqueueSnackbar('Error!', { variant: 'error' })
            setOpenScraperSinglePage(false);
        }
    }

    //call UPDATE hook
    const { mutateAsync: updateLocator, isPending: isUpdatingLocator } =
        useUpdateLocator();

    //call DELETE hook
    const { mutateAsync: deleteLocator, isPending: isDeletingLocator } =
        useDeleteLocator();

    //UPDATE action
    const handleSaveLocator = async ({ values, table }) => {

        let newData = {
            id: values.id,
            name: values.name,
            xpath: values.xpath
        }

        let originalData = {
            id: values.id,
            name: table.getRow(values.id).original.name,
            xpath: table.getRow(values.id).original.xpath
        }

        let data = {
            newData: newData,
            originalData: originalData
        }

        await updateLocator(data);
        table.setEditingRow(null);
    };

    //DELETE action
    const handleDeleteLocator = async () => {
        await deleteLocator(deleteLocatorInfo.name);
    };

    const openDeleteConfirmModal = async (value) => {
        setDeleteLocatorInfo(null)
        setOpenDeleteLocator(true);
        setDeleteLocatorInfo(value);
    };

    //READ function
    const [dataLocators, setDataLocators] = useState([]);

    const loadLocators = async () => {
        let locators = [];
        let response = await Locator.getLocators(namePageTitle, namePage, filterPage.current.project, filterPage.current.capability, filterPage.current.market, filterPage.current.env, filterPage.current.level);

        let locatorsPage = response.data.selectors

        let errorPageInfo = response.data.error
        setErrorPage(errorPageInfo)

        let isMonitoring = response.data.monitoring
        setIsMonitoring(isMonitoring)

        let monitoringTime = response.data.monitoring_time
        setMonitoringTime(monitoringTime)

        locators.push(
            ...locatorsPage.map((loc, index) => {

                const shapeStyles = { bgcolor: 'primary.main', width: 30, height: 30 };
                const shapeCircleStyles = { borderRadius: '50%' };
                const blink = keyframes` from { opacity: 0; } to { opacity: 1; }`;

                let status = null

                if (errorPageInfo === null) {
                    switch (loc.status) {
                        case "OK":
                            status = <Box component="span" title={"OK"} sx={{ ...shapeStyles, ...shapeCircleStyles, backgroundColor: '#1aae6f' }} />
                            break;
                        case "KO":
                            status = <Box component="span" title={"KO"} sx={{ ...shapeStyles, ...shapeCircleStyles, backgroundColor: '#f80031', animation: `${blink} 1.5s linear infinite` }} />
                            break;
                        case "TBD":
                            status = <Box component="span" title={"TBD"} sx={{ ...shapeStyles, ...shapeCircleStyles, backgroundColor: '#e2e6eb' }} />;
                            break;
                        default:
                            status = <Box component="span" title={loc.status} sx={{ ...shapeStyles, ...shapeCircleStyles, backgroundColor: '#e86d26', animation: `${blink} 1.5s linear infinite` }} />
                            break;
                    }
                } else {
                    status = <Box component="span" title={"WARNING"} sx={{ ...shapeStyles, ...shapeCircleStyles, backgroundColor: '#e86d26', animation: `${blink} 1.5s linear infinite` }} />
                }

                let visible = <ArgonBadge badgeContent="TBD" variant="contained" container color={"secondary"} />;
                let enabled = <ArgonBadge badgeContent="TBD" variant="contained" container color={"secondary"} />;

                if (loc.status === "OK" || loc.status === "KO") {
                    visible = loc.visible ?
                        <ArgonBadge badgeContent="True" variant="contained" container color={"success"} /> :
                        <ArgonBadge badgeContent="False" variant="contained" container color={"error"} />;
                    enabled = loc.enabled ?
                        <ArgonBadge badgeContent="True" variant="contained" container color={"success"} /> :
                        <ArgonBadge badgeContent="False" variant="contained" container color={"error"} />;
                }

                let img = null;
                if (loc.image_id) {
                    const blob = new Blob([loc.image_id.data], { type: 'image/jpeg' });
                    img = window.URL.createObjectURL(blob);
                }

                let isComponent = false;
                let typeComponent = null;
                let priorityComponent = null;
                let actionComponent = null;
                if (loc.component !== null) {
                    isComponent = true;
                    typeComponent = loc.component.type;
                    priorityComponent = loc.component.priority;
                    actionComponent = loc.component.action;
                }
                let child = null;
                if (loc.child !== null) {
                    child = loc.child.map((loc, index) => { return loc.name })
                }

                return {
                    id: (index + 1).toString(),
                    name: loc.name,
                    xpath: loc.xpath,
                    status: status,
                    visible: visible,
                    enabled: enabled,
                    href: loc.href,
                    imageLocator: img,
                    xpath_secondary: loc.xpath_secondary,
                    attribute: loc.attribute,
                    parent: loc.parent,
                    child: child,
                    isComponent: isComponent,
                    typeComponent: typeComponent,
                    priorityComponent: priorityComponent,
                    actionComponent: actionComponent
                };
            })
        );
        setDataLocators(locators);
    };

    const createLocator = async () => {
        try {
            setIsCreatingLocator(true);
            const response = await Locator.createLocatorOnDB(page, nameLocator, xpathLocator, xpathParentLocator, componentLocator, priorityLocator, actionLocator);
            if (response.status != 200) {
                enqueueSnackbar('Error!', { variant: 'error' })
                handleCloseCreateLocatorModal();
                return;
            }

            if (response.data.add_locator === -1) {
                enqueueSnackbar('Locator name already exists!', { variant: 'warning' });
                return;
            }

            if (response.data.add_locator > 0) {
                enqueueSnackbar('Locator created!', { variant: 'success' });
                handleCloseCreateLocatorModal();
            }
        } finally {
            setIsCreatingLocator(false);
            refetch();
        }
    }

    const refetch = () => {
        setRefreshCounter(refreshCounter + 1);
    }

    const updateLoop = async () => {
        try {
            if (!mounted.current) return;
            await loadLocators();
        } finally {
            setTimeout(updateLoop, 5000);
        }
    }

    useEffect(() => {
        loadLocators();
    }, [refreshCounter]);

    useEffect(() => {
        mounted.current = true;
        updateLoop();
        return () => {
            mounted.current = false;
        }
    }, []);



    //CREATE hook
    /*function useCreateLocator() {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async () => {
                
            },
            //client side optimistic update
            onMutate: () => {
                queryClient.setQueryData(['page-'+page._id], (prevLocators) => {

                    if(prevLocators?.find(locator => locator.name === nameLocator)){
                        return prevLocators;
                    } else {
                        return [
                            ...prevLocators,
                            {
                                id: (prevLocators.length + 1).toString(),
                                name: nameLocator,
                                xpath: xpathLocator,
                                status: "loading...",
                                visible: "loading...",
                                enabled: "loading...",
                                imageLocator: null,
                                xpath_secondary: [],
                                attribute: [],
                                parent: xpathParentLocator,
                                child: null,
                                isComponent: componentLocator !== null,
                                typeComponent: componentLocator ? componentLocator : null,
                                priorityComponent: priorityLocator ? priorityLocator : null,
                                actionComponent: actionLocator ? actionLocator : null
                            },
                        ]
                    }
                });
            },
        });
    }*/


    //UPDATE hook
    function useUpdateLocator() {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async (data) => {
                const response = await Locator.updateLocatorOnDB(page, data['originalData'], data['newData']);
                if (response.status === 200) {
                    if (response.data.change_locator > 0) {
                        enqueueSnackbar('Locator updated!', { variant: 'success' });
                        await refetch()
                        return Promise.resolve();
                    }
                } else {
                    enqueueSnackbar('Error!', { variant: 'error' })
                    return Promise.reject();
                }
            },
            //client side optimistic update
            onMutate: (newLocatorInfo) => {
                queryClient.setQueryData(['page-' + page._id], (prevLocators) => {
                    prevLocators?.map((loc) => {
                        return loc ? newLocatorInfo['newData'] : loc;
                    })
                });
            },
        });
    }

    //DELETE hook (delete user in api)
    function useDeleteLocator() {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async (locatorName) => {
                const response = await Locator.deleteLocatorOnDB(page, locatorName);
                if (response.status === 200) {
                    if (response.data.delete_locator > 0) {
                        enqueueSnackbar('Locator deleted!', { variant: 'success' });
                        handleCloseDeleteLocatorModal();
                        refetch();
                        return Promise.resolve();
                    }
                } else {
                    enqueueSnackbar('Error!', { variant: 'error' })
                    handleCloseDeleteLocatorModal();
                    return Promise.reject();
                }
            },
            //client side optimistic update
            onMutate: (deleteLocator) => {
                queryClient.setQueryData(['page-' + page._id], (prevLocators) =>
                    prevLocators?.filter((loc) => loc.name !== deleteLocator).map((loc, i) => {
                        loc.id = (i + 1).toString();
                        return loc;
                    })
                );
            },
        });
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableResizing: false,
                enableEditing: false,
            },
            {
                accessorKey: 'name',
                header: 'Name',
                size: 200,
            },
            {
                accessorKey: 'xpath',
                header: 'Locator',
                size: 450,
            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 150,
                enableResizing: false,
                enableEditing: false,
                sortingFn: (rowA, rowB, columnId) => {
                    const priorityMap = {
                        "KO": 0,
                        "WARNING": 1,
                        "TBD": 2,
                        "OK": 3
                    };

                    const titleA = rowA.getValue(columnId).props['title'];
                    const titleB = rowB.getValue(columnId).props['title'];

                    return priorityMap[titleA] - priorityMap[titleB];
                }
            },
            {
                accessorKey: 'visible',
                header: 'Visible',
                size: 150,
                enableResizing: false,
                enableEditing: false,
            },
            {
                accessorKey: 'enabled',
                header: 'Enabled',
                size: 170,
                enableResizing: false,
                enableEditing: false,
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: dataLocators,
        defaultColumn: {
            maxSize: 3000,
            minSize: 130,
            size: 100,
        },
        state: {
            isSaving: isUpdatingLocator || isDeletingLocator || isCreatingLocator,
        },
        enableColumnResizing: true,
        columnResizeMode: 'onEnd',
        initialState: { density: 'compact', pagination: { pageSize: 25 } },
        enableBottomToolbar: true,
        enablePagination: true,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        columnFilterDisplayMode: 'popover',
        createDisplayMode: 'custom',
        editDisplayMode: 'row',
        enableEditing: window.user.canEditLocator(),
        onEditingRowSave: handleSaveLocator,
        getRowId: (row) => row.id,  //custom row id
        muiPaginationProps: {
            rowsPerPageOptions: [25, 50, 100],
        },
        muiTableHeadProps: {
            sx: {
                padding: '0 !important',
            },
        },
        muiEditTextFieldProps: {
            inputProps: {
                sx: {
                    width: '100% !important',
                    height: '100% !important',
                },
            },
        },
        //custom expand button rotation
        muiExpandButtonProps: ({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
            },
        }),
        renderTopToolbarCustomActions: ({ table }) => (
            <ArgonBox
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >
                <ArgonButton
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                >
                    Export Data
                </ArgonButton>
                <UploadLocator user={user} namePageTitle={namePageTitle} filterPage={filterPage} />
            </ArgonBox>
        ),
        renderDetailPanel: ({ row }) =>
            <ArgonBox
                sx={{
                    width: '1500px',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Card>
                            <CardHeader
                                title="Principal xPath"
                                sx={{ textAlign: 'center' }}
                            />
                            <CardContent>
                                {row.original.xpath_secondary ?
                                    (<Grid container>
                                        <FormControl fullWidth>
                                            <RadioGroup
                                                aria-labelledby="demo-customized-radios"
                                                name="customized-radios"
                                                defaultValue={row.original.xpath}
                                            >
                                                <FormControlLabel value={row.original.xpath} control={<Radio />} label={row.original.xpath} />
                                                {row.original.xpath_secondary.map((xpath, i) => {
                                                    return (<Grid container key={i}>
                                                        <Grid item xs={11}>
                                                            <FormControlLabel
                                                                value={xpath}
                                                                key={xpath}
                                                                control={<Radio onClick={() => handleOpenDetailPanelLocatorModal("change", row.original.name, xpath)} />}
                                                                label={xpath} />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <IconButton aria-label="delete" size="small" onClick={() => handleOpenDetailPanelLocatorModal("delete", row.original.name, xpath)}>
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>)
                                                })}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>)
                                    :
                                    <ArgonTypography variant="h5" textAlign="center">N/A</ArgonTypography>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card>
                            {!editInformation ?
                                <CardHeader
                                    action={<IconButton aria-label="edit" size="small" onClick={() => handleEditInformation(row.original)}>
                                        <EditIcon />
                                    </IconButton>}
                                    title="Information"
                                    sx={{ textAlign: 'center' }}
                                />
                                :
                                <CardHeader
                                    action={<div>
                                        <IconButton aria-label="edit" color="info" onClick={() => handleSaveNewInformation(row.original)}>
                                            <SaveIcon />
                                        </IconButton>
                                        <IconButton aria-label="edit" color="error" onClick={() => handleEditInformation(row.original)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>}
                                    title="Information"
                                    sx={{ textAlign: 'center' }}
                                />
                            }
                            {!editInformation ?
                                <CardContent>
                                    {row.original.parent ?
                                        <ArgonTypography variant="body2">Parent: {row.original.parent}</ArgonTypography>
                                        :
                                        <ArgonTypography variant="body2">Parent: -</ArgonTypography>
                                    }
                                    {row.original.child ?
                                        <ArgonTypography variant="body2">Children(s): {row.original.child.join(' , ')}</ArgonTypography>
                                        :
                                        <ArgonTypography variant="body2">Children(s): -</ArgonTypography>
                                    }
                                    {row.original.isComponent &&
                                        <div>
                                            <ArgonTypography variant="body2">Component: {row.original.typeComponent}</ArgonTypography>
                                            <ArgonTypography variant="body2" >Priority: {row.original.priorityComponent}</ArgonTypography>
                                            <ArgonTypography variant="body2" >Action: {row.original.actionComponent ? row.original.actionComponent : '-'}</ArgonTypography>
                                        </div>
                                    }
                                </CardContent>
                                :
                                <CardContent sx={{ overflow: 'auto' }}>
                                    {!row.original.child &&
                                        <Grid container mb={2}>
                                            <Grid item xs={3}>
                                                <ArgonTypography variant="body2">Parent: </ArgonTypography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <ArgonSelect
                                                    isClearable
                                                    size={"medium"}
                                                    sx={{ overflow: "hidden" }}
                                                    placeholder={"Select a Parent"}
                                                    options={dataLocators
                                                        .filter(locator => locator.parent === null)
                                                        .filter(locator => locator.name !== row.original.name)
                                                        .map(locator => {
                                                            return {
                                                                label: locator.name,
                                                                value: locator.name
                                                            }
                                                        })}
                                                    defaultValue={row.original.parent ? { label: row.original.parent, value: row.original.parent } : null}
                                                    onChange={(event) => setXpathParentLocator(event ? event.value : null)}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={3} >
                                            <ArgonTypography variant="body2">Component: </ArgonTypography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <ArgonSelect
                                                isClearable
                                                size={"medium"}
                                                sx={{ overflow: "hidden" }}
                                                placeholder={"Select a component"}
                                                options={[{ label: "Cookie", value: "cookie" }]}
                                                defaultValue={row.original.typeComponent ? { label: row.original.typeComponent, value: row.original.typeComponent } : null}
                                                onChange={(event) => setComponentLocator(event ? event.value : null)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ArgonTypography variant="body2">Priority: </ArgonTypography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <ArgonSelect
                                                isClearable
                                                size={"medium"}
                                                sx={{ overflow: "hidden" }}
                                                placeholder={"Select a priority"}
                                                options={[{ label: "1", value: "1" }, { label: "2", value: "2" }, { label: "3", value: "3" }]}
                                                defaultValue={row.original.priorityComponent ? { label: row.original.priorityComponent, value: row.original.priorityComponent } : null}
                                                onChange={(event) => setPriorityLocator(event ? event.value : null)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ArgonTypography variant="body2">Action: </ArgonTypography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <ArgonSelect
                                                isClearable
                                                size={"medium"}
                                                sx={{ overflow: "hidden" }}
                                                placeholder={"Select an action"}
                                                options={[{ label: "Click", value: "Click" }]}
                                                defaultValue={row.original.actionComponent ? { label: row.original.actionComponent, value: row.original.actionComponent } : null}
                                                onChange={(event) => setActionLocator(event ? event.value : null)}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            }
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Card>
                            <CardContent>
                                <ArgonTypography variant="h5" textAlign="center"> Image</ArgonTypography>
                                {row.original.imageLocator ? (
                                    <ArgonBox mt={2}>
                                        <Card>
                                            <CardMedia
                                                component="img"
                                                alt="icon locator"
                                                height="140"
                                                image={row.original.imageLocator}
                                            />
                                            <CardActions>
                                                <ArgonButton variant="gradient" size="small">Crop new Icon</ArgonButton>
                                                <ArgonButton variant="gradient" size="small">Delete</ArgonButton>
                                            </CardActions>
                                        </Card>
                                    </ArgonBox>
                                ) :
                                    (<ArgonBox mt={2}>
                                        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                                            <Grid item >
                                                <ArgonButton variant="gradient" color="info" disabled={true}>
                                                    <Icon>cut</Icon>&nbsp;
                                                    Crop Icon
                                                </ArgonButton>
                                            </Grid>
                                        </Grid>
                                    </ArgonBox>)}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </ArgonBox>,
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" disabled={!window.user.canDeleteLocator()} onClick={() => openDeleteConfirmModal(row.original)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        )
    });

    return (
        <Grid container justifyContent="center" alignItems="center" mt={5} id={page._id} >
            <Grid item xs={12} lg={12}>
                <Card>
                    <CardHeader
                        title={isTypingPageTitle ?
                            <Grid container>
                                {errorPage !== null &&
                                    <Grid item xs={12} pt={1} pb={1}>
                                        <Alert icon={<WarningRounded fontSize="inherit" />} severity="warning" onClose={() => handleDeleteErrorPage()}>
                                            Warning during the monitoring page. Error: {errorPage}
                                        </Alert>
                                    </Grid>
                                }
                                {isMonitoring ?
                                    <Grid item xs={12} pt={1} pb={1}>
                                        <Alert icon={<InfoRounded fontSize="inherit" />} severity="info">
                                            <ArgonTypography sx={{
                                                "@keyframes blink": {
                                                    "from": {
                                                        opacity: "0"
                                                    },
                                                    "to": {
                                                        opacity: "1"
                                                    }
                                                },
                                                animation: "blink 1.5s linear infinite"
                                            }} variant="body2">
                                                Monitoring or Self-healing or Scraper in progress...
                                            </ArgonTypography>
                                        </Alert>
                                    </Grid>
                                    :
                                    <Grid item xs={12} pb={1}>
                                        <ArgonTypography variant="caption" >Last Monitoring: {monitoringTime ? monitoringTime : "Never"}</ArgonTypography>
                                    </Grid>
                                }
                                <Grid item xs={6}>
                                    <Grid container>
                                        {filterPage.current.project !== "" &&
                                            <Grid item textAlign="center" pr={1}>
                                                <ArgonBadge badgeContent={filterPage.current.project} variant="gradient" size="xs" color="secondary" container />
                                            </Grid>
                                        }
                                        {filterPage.current.capability !== "" &&
                                            <Grid item textAlign="center" pr={1}>
                                                <ArgonBadge badgeContent={filterPage.current.capability} variant="gradient" size="xs" color="primary" container />
                                            </Grid>
                                        }
                                        {filterPage.current.market !== "" &&
                                            <Grid item textAlign="center" pr={1}>
                                                <ArgonBadge badgeContent={filterPage.current.market} variant="gradient" size="xs" color="dark" container />
                                            </Grid>
                                        }
                                        {filterPage.current.env !== "" &&
                                            <Grid item textAlign="center" pr={1}>
                                                <ArgonBadge badgeContent={filterPage.current.env} variant="gradient" size="xs" color="info" container />
                                            </Grid>
                                        }
                                        {filterPage.current.level !== "" &&
                                            <Grid item textAlign="center" pr={1}>
                                                <ArgonBadge badgeContent={filterPage.current.level + "° level"} variant="gradient" size="xs" color="info" container />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} pt={1}>
                                    <ArgonInput
                                        autoFocus
                                        value={newNamePageTitle}
                                        onChange={({ currentTarget }) => setNewNamePageTitle(currentTarget.value)}
                                        onBlur={({ currentTarget }) => {
                                            setIsTypingPageTitle(false);
                                            updateLocatorPageTitle(currentTarget.value)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            :
                            <Grid container>
                                {errorPage !== null &&
                                    <Grid item xs={12} pt={1} pb={1}>
                                        <Alert icon={<WarningRounded fontSize="inherit" />} severity="warning" onClose={() => handleDeleteErrorPage()}>
                                            Warning during the monitoring page. Error: {errorPage}
                                        </Alert>
                                    </Grid>
                                }
                                {isMonitoring ?
                                    <Grid item xs={12} pt={1} pb={1}>
                                        <Alert icon={<InfoRounded fontSize="inherit" />} severity="info">
                                            <ArgonTypography sx={{
                                                "@keyframes blink": {
                                                    "from": {
                                                        opacity: "0"
                                                    },
                                                    "to": {
                                                        opacity: "1"
                                                    }
                                                },
                                                animation: "blink 1.5s linear infinite"
                                            }} variant="body2">
                                                Monitoring or Self-healing or Scraper in progress...
                                            </ArgonTypography>
                                        </Alert>
                                    </Grid>
                                    :
                                    <Grid item xs={12} pb={1}>
                                        <ArgonTypography variant="caption" >Last Monitoring: {monitoringTime ? monitoringTime : "Never"}</ArgonTypography>
                                    </Grid>
                                }
                                <Grid item xs={6}>
                                    <Grid container>
                                        {filterPage.current.project !== "" &&
                                            <Grid item textAlign="center" pr={1}>
                                                <ArgonBadge badgeContent={filterPage.current.project} variant="gradient" size="xs" color="secondary" container />
                                            </Grid>
                                        }
                                        {filterPage.current.capability !== "" &&
                                            <Grid item textAlign="center" pr={1}>
                                                <ArgonBadge badgeContent={filterPage.current.capability} variant="gradient" size="xs" color="primary" container />
                                            </Grid>
                                        }
                                        {filterPage.current.market !== "" &&
                                            <Grid item textAlign="center" pr={1}>
                                                <ArgonBadge badgeContent={filterPage.current.market} variant="gradient" size="xs" color="dark" container />
                                            </Grid>
                                        }
                                        {filterPage.current.env !== "" &&
                                            <Grid item textAlign="center" pr={1}>
                                                <ArgonBadge badgeContent={filterPage.current.env} variant="gradient" size="xs" color="info" container />
                                            </Grid>
                                        }
                                        {filterPage.current.level !== "" &&
                                            <Grid item textAlign="center" pr={1}>
                                                <ArgonBadge badgeContent={filterPage.current.level + "° level"} variant="gradient" size="xs" color="info" container />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} pt={1}>
                                    <ArgonTypography
                                        variant="h5"
                                        fontWeight="medium"
                                        textTransform="capitalize"
                                        onClick={() => {
                                            setIsTypingPageTitle(true);
                                            setNewNamePageTitle(namePageTitle)
                                        }}>
                                        {namePageTitle}
                                    </ArgonTypography>
                                </Grid>
                            </Grid>
                        }

                        subheader={isTypingPage ?
                            <Grid container>
                                <Grid item xs={12}>
                                    <ArgonInput
                                        sx={{ marginTop: '10px' }}
                                        size="small"
                                        autoFocus
                                        value={newPage}
                                        onChange={({ currentTarget }) => setNewPage(currentTarget.value)}
                                        onBlur={({ currentTarget }) => {
                                            setIsTypingPage(false);
                                            updateLocatorPage(currentTarget.value)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            :
                            <ArgonTypography
                                variant="overline"
                                onClick={() => {
                                    setIsTypingPage(true);
                                    setNewPage(namePage)
                                }}>
                                {namePage.replace(/\s/g, "") === "" ? "No URL" : namePage}
                            </ArgonTypography>
                        }

                        action={
                            <ExpandMore expand={expanded.toString()} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                                <ExpandMoreIcon />
                            </ExpandMore>
                        }
                    />
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Grid container>
                                <Grid container spacing={2}>
                                    {filterPage.current.level === "1" &&
                                        <Grid item>
                                            <ArgonButton variant="gradient" color="info" disabled={!window.user.canSelfHealing() || dataLocators.length === 0} onClick={() => handleOpenSelfHealingSinglePageModal()}>
                                                <Icon>healing</Icon>&nbsp;
                                                Self Healing
                                            </ArgonButton>
                                        </Grid>
                                    }
                                    {filterPage.current.level === "1" &&
                                        <Grid item>
                                            <ArgonButton variant="gradient" color="info" disabled={dataLocators.length !== 0} onClick={() => handleOpenScraperSinglePageModal()}>
                                                <Icon>travel_explore</Icon>&nbsp;
                                                Scraper
                                            </ArgonButton>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <ArgonButton variant="gradient" color="info" disabled={!window.user.canAddLocator()} onClick={() => handleOpenCreateLocatorModal()}>
                                            <Icon>add</Icon>&nbsp;
                                            Add Locator
                                        </ArgonButton>
                                    </Grid>
                                    <Grid item>
                                        <ArgonButton variant="gradient" color="error" onClick={() => handleOpenDeletePageModal()}>
                                            <Icon>delete</Icon>&nbsp;
                                            Delete Page
                                        </ArgonButton>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12} paddingTop={2}>
                                    <MaterialReactTable table={table} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Collapse>
                </Card>
            </Grid>
            <Modal open={openDeleteLocator}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleCloseDeleteLocatorModal(event, reason);
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                aria-labelledby="delete-locator"
                aria-describedby="delete-locator" >
                <ArgonBox sx={style}>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} mb={2} textAlign="center">
                            <ArgonTypography variant="h3" fontWeight="medium">
                                Delete Locator
                            </ArgonTypography>
                            <ArgonTypography variant="h6" fontWeight="regular">
                                Are you sure you want to delete this locator?
                            </ArgonTypography>
                            {deleteLocatorInfo && deleteLocatorInfo.child !== null &&
                                <ArgonBox mt={2}>
                                    <ArgonTypography variant="h6" fontWeight="bold">
                                        This locator contains {deleteLocatorInfo.child.length} child locator(s):
                                    </ArgonTypography>
                                    {deleteLocatorInfo.child.map((child, index) => {
                                        return <ArgonTypography key={index} variant="h6" >&nbsp; {child}</ArgonTypography>
                                    })}
                                </ArgonBox>
                            }
                        </Grid>
                        <Grid item>
                            <ArgonButton color="dark" variant="gradient" onClick={handleCloseDeleteLocatorModal}>Back</ArgonButton>
                        </Grid>
                        <Grid item>
                            <ArgonButton color="error" variant="gradient" onClick={handleDeleteLocator}>Delete</ArgonButton>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </Modal>
            <Modal open={openDeletePage}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleCloseDeleteLocatorModal(event, reason);
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                aria-labelledby="delete-page"
                aria-describedby="delete-page" >
                <ArgonBox sx={style}>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} mb={2} textAlign="center">
                            <ArgonTypography variant="h3" fontWeight="medium">
                                Delete Page
                            </ArgonTypography>
                            <ArgonTypography variant="h6" fontWeight="regular">
                                Are you sure you want to delete this page?
                            </ArgonTypography>
                        </Grid>
                        <Grid item>
                            <ArgonButton color="dark" variant="gradient" onClick={handleCloseDeletePageModal}>Back</ArgonButton>
                        </Grid>
                        <Grid item>
                            <ArgonButton color="error" variant="gradient" onClick={handleDeletePage}>Delete</ArgonButton>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </Modal>
            <Modal open={openCreateLocator}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleCloseCreateLocatorModal(event, reason);
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                aria-labelledby="create-locator"
                aria-describedby="create-locator" >
                <ArgonBox sx={style}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} mb={2} textAlign="center">
                            <ArgonTypography variant="h3" fontWeight="medium">
                                Create Locator
                            </ArgonTypography>
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <ArgonTypography variant="h6" fontWeight="medium">
                                Name Locator *
                            </ArgonTypography>
                            <ArgonInput
                                placeholder="Name"
                                startAdornment={<ArgonBox component="i" className="ni ni-bold-right" mr={1} />}
                                onChange={(event) => setNameLocator(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <ArgonTypography variant="h6" fontWeight="medium">
                                xPath Locator *
                            </ArgonTypography>
                            <ArgonInput
                                placeholder="xPath"
                                startAdornment={<ArgonBox component="i" className="ni ni-bold-right" mr={1} />}
                                onChange={(event) => setXpathLocator(event.target.value)}
                            />
                            <Divider />
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <ArgonTypography variant="h6" fontWeight="medium">
                                xPath Parent Locator
                            </ArgonTypography>
                            <ArgonSelect
                                isClearable
                                size={"medium"}
                                sx={{ overflow: "hidden" }}
                                placeholder={"Select a Parent"}
                                options={dataLocators
                                    .filter(locator => locator.parent === null)
                                    .map(locator => {
                                        return {
                                            label: locator.name,
                                            value: locator.name
                                        }
                                    })}
                                onChange={(event) => setXpathParentLocator(event ? event.value : null)}
                            />
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <ArgonTypography variant="h6" fontWeight="medium">
                                        Component
                                    </ArgonTypography>
                                    <ArgonSelect
                                        isClearable
                                        size={"medium"}
                                        sx={{ overflow: "hidden" }}
                                        placeholder={"Select a component"}
                                        options={[{ label: "Cookie", value: "cookie" }]}
                                        onChange={(event) => setComponentLocator(event ? event.value : null)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <ArgonTypography variant="h6" fontWeight="medium">
                                        Priority
                                    </ArgonTypography>
                                    <ArgonSelect
                                        isClearable
                                        size={"medium"}
                                        sx={{ overflow: "hidden" }}
                                        placeholder={"Select a priority"}
                                        options={[{ label: "1", value: "1" }, { label: "2", value: "2" }, { label: "3", value: "3" }]}
                                        onChange={(event) => setPriorityLocator(event ? event.value : null)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <ArgonTypography variant="h6" fontWeight="medium">
                                        Action
                                    </ArgonTypography>
                                    <ArgonSelect
                                        isClearable
                                        size={"medium"}
                                        sx={{ overflow: "hidden" }}
                                        placeholder={"Select an action"}
                                        options={[{ label: "Click", value: "Click" }]}
                                        onChange={(event) => setActionLocator(event ? event.value : null)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} mt={1} justifyContent="center">
                        <Grid item>
                            <ArgonButton color="dark" variant="gradient" onClick={handleCloseCreateLocatorModal}>Cancel</ArgonButton>
                        </Grid>
                        <Grid item>
                            <ArgonButton color="info" variant="gradient" onClick={createLocator} disabled={nameLocator === "" || xpathLocator === ""}>Create</ArgonButton>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </Modal>
            <Modal open={openSelfHealingSinglePage}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleCloseSelfHealingSinglePageModal(event, reason);
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                aria-labelledby="self-healing-locator"
                aria-describedby="self-healing-locator" >
                <ArgonBox sx={style}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} mb={1}>
                            <Grid container justifyContent="center">
                                {filterPage.current.project !== "" &&
                                    <Grid item textAlign="center" pr={1}>
                                        <ArgonBadge badgeContent={filterPage.current.project} variant="gradient" size="xs" color="secondary" container />
                                    </Grid>
                                }
                                {filterPage.current.capability !== "" &&
                                    <Grid item textAlign="center" pr={1}>
                                        <ArgonBadge badgeContent={filterPage.current.capability} variant="gradient" size="xs" color="primary" container />
                                    </Grid>
                                }
                                {filterPage.current.market !== "" &&
                                    <Grid item textAlign="center" pr={1}>
                                        <ArgonBadge badgeContent={filterPage.current.market} variant="gradient" size="xs" color="dark" container />
                                    </Grid>
                                }
                                {filterPage.current.env !== "" &&
                                    <Grid item textAlign="center" pr={1}>
                                        <ArgonBadge badgeContent={filterPage.current.env} variant="gradient" size="xs" color="info" container />
                                    </Grid>
                                }
                                {filterPage.current.level !== "" &&
                                    <Grid item textAlign="center" pr={1}>
                                        <ArgonBadge badgeContent={filterPage.current.level + "° level"} variant="gradient" size="xs" color="info" container />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} mb={1} textAlign="center">
                            <ArgonTypography variant="h2" fontWeight="medium">
                                {namePageTitle}
                            </ArgonTypography>
                        </Grid>
                        <Grid item xs={12} mb={1} textAlign="center">
                            <ArgonTypography variant="h8" fontWeight="medium">
                                {namePage}
                            </ArgonTypography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} mt={1} justifyContent="center">
                        <Grid item>
                            <ArgonButton color="dark" variant="gradient" onClick={handleCloseSelfHealingSinglePageModal}>Cancel</ArgonButton>
                        </Grid>
                        <Grid item>
                            <ArgonButton color="success" variant="gradient" onClick={handleRunSelfHealing} >Run</ArgonButton>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </Modal>
            <Modal open={openScraperSinglePage}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleCloseScraperSinglePageModal(event, reason);
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                aria-labelledby="search-locator"
                aria-describedby="search-locator" >
                <ArgonBox sx={style}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} mb={1}>
                            <Grid container justifyContent="center">
                                {filterPage.current.project !== "" &&
                                    <Grid item textAlign="center" pr={1}>
                                        <ArgonBadge badgeContent={filterPage.current.project} variant="gradient" size="xs" color="secondary" container />
                                    </Grid>
                                }
                                {filterPage.current.capability !== "" &&
                                    <Grid item textAlign="center" pr={1}>
                                        <ArgonBadge badgeContent={filterPage.current.capability} variant="gradient" size="xs" color="warning" container />
                                    </Grid>
                                }
                                {filterPage.current.market !== "" &&
                                    <Grid item textAlign="center" pr={1}>
                                        <ArgonBadge badgeContent={filterPage.current.market} variant="gradient" size="xs" color="dark" container />
                                    </Grid>
                                }
                                {filterPage.current.env !== "" &&
                                    <Grid item textAlign="center" pr={1}>
                                        <ArgonBadge badgeContent={filterPage.current.env} variant="gradient" size="xs" color="info" container />
                                    </Grid>
                                }
                                {filterPage.current.level !== "" &&
                                    <Grid item textAlign="center" pr={1}>
                                        <ArgonBadge badgeContent={filterPage.current.level + "° level"} variant="gradient" size="xs" color="info" container />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} mb={1} textAlign="center">
                            <ArgonTypography variant="h2" fontWeight="medium">
                                {namePageTitle}
                            </ArgonTypography>
                        </Grid>
                        <Grid item xs={12} mb={1} textAlign="center">
                            <ArgonTypography variant="h8" fontWeight="medium">
                                {namePage}
                            </ArgonTypography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} mt={1} justifyContent="center">
                        <Grid item>
                            <ArgonButton color="dark" variant="gradient" onClick={handleCloseScraperSinglePageModal}>Cancel</ArgonButton>
                        </Grid>
                        <Grid item>
                            <ArgonButton color="success" variant="gradient" onClick={handleRunScraper} >Scrape</ArgonButton>
                        </Grid>
                        {samePageDifferentMarket &&
                            <Grid item>
                                <ArgonButton color="success" variant="gradient">Scrape from principal market</ArgonButton>
                            </Grid>
                        }
                    </Grid>
                </ArgonBox>
            </Modal>
        </Grid>
    );
}

const queryClient = new QueryClient();

const PageLocators = ({ page, pagesLocator, setPagesLocator, isExpanded, loadPagesLocator }) => (
    <QueryClientProvider client={queryClient}>
        <PageLocatorsTable page={page} pagesLocator={pagesLocator} setPagesLocator={setPagesLocator} isExpanded={isExpanded} loadPagesLocator={loadPagesLocator} />
    </QueryClientProvider>
);


export default PageLocators;
