import React, { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import useAuth from "utils/useAuth";
import ArgonBox from "components/ArgonBox";
import Footer from "components/Footer";
import Header from "components/Header";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import { Locator } from "../../api/BackendApi/Locator";
import PageLocators from "./components/PageLocators";
import ArgonTypography from "../../components/ArgonTypography";
import Card from "@mui/material/Card";
import ArgonButton from "../../components/ArgonButton";
import { CircularProgress, Icon } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import ArgonInput from "../../components/ArgonInput";
import Modal from "@mui/material/Modal";
import { TestConfig } from "../../api/BackendApi/TestConfiguration";
import ArgonSelect from "../../components/ArgonSelect";
import { enqueueSnackbar } from "notistack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { CalendarMonth } from "@mui/icons-material";
import PageSelection from "./components/PageSelection";
import ScheduleConfiguration from "./components/ScheduleConfiguration";

function LocatorMonitoring() {
    const [pagesLocator, setPagesLocator] = useState(null);
    const [openCreatePage, setOpenCreatePage] = useState(false);
    const [openSchedule, setOpenSchedule] = useState(false);

    const [pagesForSchedule, setPagesForSchedule] = useState([]);
    const [scheduleInfo, setScheduleInfo] = useState(null);

    const steps = ["Page Selection", "Schedule Configuration"];
    const [activeStep, setActiveStep] = useState(0);
    const isLastStep = activeStep === steps.length - 1;
    const handleNextSchedule = () => setActiveStep(activeStep + 1);
    const handleBackSchedule = () => setActiveStep(activeStep - 1);

    const [pageTitle, setPageTitle] = useState("");
    const [page, setPage] = useState("");
    const [allProject, setAllProject] = useState([]);
    const [allCapability, setAllCapability] = useState([]);
    const [allMarket, setAllMarket] = useState([]);
    const [allEnv, setAllEnv] = useState([]);
    const [allLevel, setAllLevel] = useState([{ value: "1", label: "1° level" },
    { value: "2", label: "2° level" },
    { value: "3", label: "3° level" },
    { value: "4", label: "4° level" }]);

    const [project, setProject] = useState("");
    const [capability, setCapability] = useState("");
    const [market, setMarket] = useState("");
    const [env, setEnv] = useState("");
    const [level, setLevel] = useState("");

    const projectFiltered = useRef("");
    const capabilityFiltered = useRef("");
    const marketFiltered = useRef("");
    const elvFiltered = useRef("");
    const levelFiltered = useRef("");

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 500,
        bgcolor: 'background.paper',
        border: '2px',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
    };

    const isLoggedIn = useAuth().ensureLoggedIn();
    if (!isLoggedIn) {
        return <Navigate replace to="/sign-in" />;
    }

    useEffect(() => {
        async function fetchData() {
            const responseProject = await TestConfig.getConfigsByName("Project");
            if (responseProject.status === 200 && responseProject.data.Project) {
                var allProject = [];
                responseProject.data.Project.forEach(project => {
                    project.filters
                        .filter(filter => filter.module.name === "Locator Monitoring" && filter.enabled)
                        .forEach(() => {
                            allProject.push({ value: project.name, label: project.name });
                        });
                });
                setAllProject(allProject);
            }

            const responseCapability = await TestConfig.getConfigsByName("Capability");
            if (responseCapability.status === 200 && responseCapability.data.Capability) {
                var allCapability = [];
                responseCapability.data.Capability.forEach(capability => {
                    capability.filters
                        .filter(filter => filter.module.name === "Locator Monitoring" && filter.enabled)
                        .forEach(() => {
                            allCapability.push({ value: capability.name, label: capability.name });
                        });
                });
                setAllCapability(allCapability);
            }

            const responseMarket = await TestConfig.getConfigsByName("Market");
            if (responseMarket.status === 200 && responseMarket.data.Market) {
                var allMarket = [];
                responseMarket.data.Market.forEach(market => {
                    market.filters
                        .filter(filter => filter.module.name === "Locator Monitoring" && filter.enabled)
                        .forEach(() => {
                            allMarket.push({ value: market.name, label: market.name });
                        });
                });
                setAllMarket(allMarket);
            }

            const responseEnv = await TestConfig.getConfigsByName("Environment");
            if (responseEnv.status === 200 && responseEnv.data.Environment) {
                var allEnv = [];
                responseEnv.data.Environment.forEach(environment => {
                    environment.filters
                        .filter(filter => filter.module.name === "Locator Monitoring" && filter.enabled)
                        .forEach(() => {
                            allEnv.push({ value: environment.name, label: environment.name });
                        });
                });
                setAllEnv(allEnv);
            }

            await loadPagesLocator();
        }
        fetchData().then(r => console.log("Locator loaded"))
    }, []);

    const handleCreatePage = async () => {
        const response = await Locator.createLocatorPageOnDB(pageTitle, page, project, capability, market, env, level);
        if (response.status === 200) {
            if (response.data.add_page !== -1) {
                enqueueSnackbar('Locator Page created!', { variant: 'success' });
                handleCloseCreatePageModal();
                await loadPagesLocator();
            } else {
                enqueueSnackbar('Locator Page already exists!', { variant: 'warning' });
            }
        } else {
            enqueueSnackbar('Error!', { variant: 'error' })
            handleCloseCreatePageModal();
        }
    };

    const handleScheduleMonitoring = async () => {
        const response = await Locator.scheduleLocatorMonitoringOnDB(pagesForSchedule, scheduleInfo);
        if (response.status === 200) {
            enqueueSnackbar('Locator Monitoring started!', { variant: 'success' });
            handleCloseScheduleModal();
        } else {
            enqueueSnackbar('Error!', { variant: 'error' })
            handleCloseScheduleModal();
        }
    }

    const handleOpenCreatePageModal = () => {
        setPageTitle("");
        setPage("");
        setProject("");
        setCapability("");
        setMarket("");
        setEnv("");
        setLevel("");
        setOpenCreatePage(true);
    }

    const handleScheduleModal = () => {
        setOpenSchedule(true);
    }

    const handleCloseScheduleModal = () => {
        setOpenSchedule(false);
        setPagesForSchedule([]);
        setScheduleInfo(null);
        setActiveStep(0);
    }

    const handleCloseCreatePageModal = () => {
        setOpenCreatePage(false);
    }

    const handleFilterSelected = (event, name) => {
        if (event === null) {
            switch (name) {
                case "project":
                    projectFiltered.current = "";
                    break;
                case "capability":
                    capabilityFiltered.current = "";
                    break;
                case "market":
                    marketFiltered.current = "";
                    break;
                case "env":
                    elvFiltered.current = "";
                    break;
                case "level":
                    levelFiltered.current = "";
                    break;
            }
        } else {
            switch (name) {
                case "project":
                    projectFiltered.current = event.value;
                    break;
                case "capability":
                    capabilityFiltered.current = event.value;
                    break;
                case "market":
                    marketFiltered.current = event.value;
                    break;
                case "env":
                    elvFiltered.current = event.value;
                    break;
                case "level":
                    levelFiltered.current = event.value;
                    break;
            }
        }
        loadPagesLocator();
    }

    const loadPagesLocator = async () => {

        let response = null;

        if (projectFiltered.current === "" && capabilityFiltered.current === "" && marketFiltered.current === "" && elvFiltered.current === "") {
            response = await Locator.getLocatorPages();
        } else {
            response = await Locator.getLocatorPagesByFilters(projectFiltered.current, capabilityFiltered.current, marketFiltered.current, elvFiltered.current);
        }
        if (response.status === 200) {
            if (response.data.pagesLocator !== undefined) {
                if (response.data.pagesLocator.length > 0) {
                    setPagesLocator(response.data.pagesLocator);
                } else {
                    setPagesLocator([]);
                }
            } else {
                setPagesLocator([]);
            }
        }
    };

    const getPagesLocator = () => {
        if (pagesLocator == null) {
            return (
                <ArgonBox
                    style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "10em" }}>
                    <CircularProgress size={64} />
                </ArgonBox>
            );
        } else if (pagesLocator.length === 0) {
            return (
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} lg={12} sx={{ textAlign: "center", paddingTop: "12em", paddingBottom: "15em" }}>
                        <ArgonTypography variant="h2">No Data</ArgonTypography>
                    </Grid>
                </Grid>
            );
        } else {
            const pages = [];
            let isExpanded = true;
            for (let i = 0; i < pagesLocator.length; i++) {
                if (i > 0) {
                    isExpanded = false
                }
                pages.push(<PageLocators page={pagesLocator[i]} pagesLocator={pagesLocator} setPagesLocator={setPagesLocator} isExpanded={isExpanded} key={pagesLocator[i]._id} loadPagesLocator={loadPagesLocator} />)
            }
            return pages;
        }
    }

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <PageSelection pagesLocator={pagesLocator} pagesForSchedule={pagesForSchedule} setPagesForSchedule={setPagesForSchedule} />;
            case 1:
                return <ScheduleConfiguration scheduleInfo={scheduleInfo} setScheduleInfo={setScheduleInfo} />;
            default:
                return 'Unknown stepIndex';
        }
    }

    return (
        <DashboardLayout>
            <Header />
            <Modal open={openCreatePage}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleCloseCreatePageModal(event, reason);
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                aria-labelledby="create-locator"
                aria-describedby="create-locator" >
                <ArgonBox sx={style}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} mb={2} textAlign="center">
                            <ArgonTypography variant="h3" fontWeight="medium">
                                Create Page
                            </ArgonTypography>
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <ArgonTypography variant="h6" fontWeight="medium">
                                Page Title *
                            </ArgonTypography>
                            <ArgonInput
                                placeholder="ex. Homepage"
                                startAdornment={<ArgonBox component="i" className="ni ni-bold-right" mr={1} />}
                                onChange={(event) => setPageTitle(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <ArgonTypography variant="h6" fontWeight="medium">
                                Page URL
                            </ArgonTypography>
                            <ArgonInput
                                placeholder="ex. https://www.reply.com/"
                                startAdornment={<ArgonBox component="i" className="ni ni-bold-right" mr={1} />}
                                onChange={(event) => setPage(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {allProject.length > 0 &&
                                    <Grid item xs={3}>
                                        <ArgonTypography variant="h6" fontWeight="medium">
                                            Project *
                                        </ArgonTypography>
                                        <ArgonSelect
                                            placeholder="Select a Project"
                                            options={allProject}
                                            onChange={(event) => setProject(event.value)}
                                            startAdornment={<ArgonBox component="i" className="ni ni-bold-right" mr={1} />}
                                        />
                                    </Grid>
                                }
                                {allCapability.length > 0 &&
                                    <Grid item xs={3}>
                                        <ArgonTypography variant="h6" fontWeight="medium">
                                            Capability *
                                        </ArgonTypography>
                                        <ArgonSelect
                                            placeholder="Select a Capability"
                                            options={allCapability}
                                            onChange={(event) => setCapability(event.value)}
                                            startAdornment={<ArgonBox component="i" className="ni ni-bold-right" mr={1} />}
                                        />
                                    </Grid>
                                }
                                {allMarket.length > 0 &&
                                    <Grid item xs={3}>
                                        <ArgonTypography variant="h6" fontWeight="medium">
                                            Market *
                                        </ArgonTypography>
                                        <ArgonSelect
                                            placeholder="Select a Market"
                                            options={allMarket}
                                            onChange={(event) => setMarket(event.value)}
                                            startAdornment={<ArgonBox component="i" className="ni ni-bold-right" mr={1} />}
                                        />
                                    </Grid>
                                }
                                {allEnv.length > 0 &&
                                    <Grid item xs={3}>
                                        <ArgonTypography variant="h6" fontWeight="medium">
                                            Environment *
                                        </ArgonTypography>
                                        <ArgonSelect
                                            placeholder="Select a Environment"
                                            options={allEnv}
                                            onChange={(event) => setEnv(event.value)}
                                            startAdornment={<ArgonBox component="i" className="ni ni-bold-right" mr={1} />}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={3}>
                                    <ArgonTypography variant="h6" fontWeight="medium">
                                        Level
                                    </ArgonTypography>
                                    <ArgonSelect
                                        placeholder="Select a Level"
                                        options={allLevel}
                                        onChange={(event) => setLevel(event.value)}
                                        startAdornment={<ArgonBox component="i" className="ni ni-bold-right" mr={1} />}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} mt={1} justifyContent="center">
                        <Grid item>
                            <ArgonButton color="dark" variant="gradient" onClick={handleCloseCreatePageModal}>Cancel</ArgonButton>
                        </Grid>
                        <Grid item>
                            <ArgonButton color="info" variant="gradient" onClick={handleCreatePage} disabled={pageTitle === "" || (project === "" && allProject.length !== 0) || (capability === "" && allCapability.length !== 0) || (market === "" && allMarket.length !== 0) || (env === "" && allEnv.length !== 0)}>Create</ArgonButton>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </Modal>
            <Modal open={openSchedule}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleCloseScheduleModal(event, reason);
                    }
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                aria-labelledby="schedule-page"
                aria-describedby="schedule-page" >
                <ArgonBox sx={style}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} mb={2} textAlign="center">
                            <ArgonTypography variant="h3" fontWeight="medium">
                                Schedule Monitoring
                            </ArgonTypography>
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <ArgonBox p={2}>
                                <Stepper activeStep={activeStep} alternativeLabel sx={{ marginTop: "1em", marginBottom: "1.5em" }}>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </ArgonBox>
                            <ArgonBox maxHeight="50vh" overflow="auto">
                                {getStepContent(activeStep)}
                            </ArgonBox>
                            <ArgonBox mt={5} mb={2} width="100%" display="flex" justifyContent="space-between">
                                <ArgonBox width="100%" display="flex">
                                    <ArgonButton variant="gradient" color="dark" sx={{ marginRight: "1em" }} onClick={handleCloseScheduleModal}>
                                        Close
                                    </ArgonButton>
                                    {activeStep === 0 ? (
                                        <ArgonBox />
                                    ) : (
                                        <ArgonButton variant="gradient" color="light" onClick={handleBackSchedule}>
                                            Back
                                        </ArgonButton>
                                    )}
                                </ArgonBox>
                                {activeStep === 0 && (
                                    <ArgonButton
                                        variant="gradient"
                                        color="light"
                                        disabled={pagesForSchedule.length === 0}
                                        onClick={!isLastStep ? handleNextSchedule : undefined}
                                    >
                                        Next
                                    </ArgonButton>)}
                                {activeStep === 1 && (
                                    <ArgonButton
                                        color="info"
                                        loading={false}
                                        loadingPosition="start"
                                        startIcon={<CalendarMonth />}
                                        variant="gradient"
                                        onClick={handleScheduleMonitoring}
                                    >
                                        <span>Schedule</span>
                                    </ArgonButton>
                                )}
                            </ArgonBox>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </Modal>
            <ArgonBox mt={10}>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <ArgonBox>
                            <Grid container p={1}>
                                <Grid item xs={8}>
                                    <Grid container spacing={2}>
                                        {allProject.length > 1 &&
                                            <Grid item xs={3} >
                                                <Grid container justifyContent={"start"}>
                                                    <Grid item width="100%">
                                                        <ArgonSelect
                                                            placeholder="Filter by Project"
                                                            options={allProject}
                                                            isClearable
                                                            onChange={(event) => handleFilterSelected(event, "project")} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {allCapability.length > 0 &&
                                            <Grid item xs={3} >
                                                <Grid container justifyContent={"start"}>
                                                    <Grid item width="100%">
                                                        <ArgonSelect
                                                            placeholder="Filter by Capability"
                                                            options={allCapability}
                                                            isClearable
                                                            onChange={(event) => handleFilterSelected(event, "capability")} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {allMarket.length > 0 &&
                                            <Grid item xs={3} >
                                                <Grid container justifyContent={"start"}>
                                                    <Grid item width="100%">
                                                        <ArgonSelect
                                                            placeholder="Filter by Market"
                                                            options={allMarket}
                                                            isClearable
                                                            onChange={(event) => handleFilterSelected(event, "market")} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {allEnv.length > 0 &&
                                            <Grid item xs={3} >
                                                <Grid container justifyContent={"start"}>
                                                    <Grid item width="100%">
                                                        <ArgonSelect
                                                            placeholder="Filter by Environment"
                                                            options={allEnv}
                                                            isClearable
                                                            onChange={(event) => handleFilterSelected(event, "env")} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container spacing={2} justifyContent={"end"}>
                                        <Grid item>
                                            <ArgonButton variant="gradient" color="info" onClick={() => handleScheduleModal()}>
                                                <Icon>schedule</Icon>&nbsp;
                                                Schedule Monitoring
                                            </ArgonButton>
                                        </Grid>
                                        <Grid item>
                                            <ArgonButton variant="gradient" color="info" onClick={() => handleOpenCreatePageModal()}>
                                                <Icon>add</Icon>&nbsp;
                                                Add New Page
                                            </ArgonButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ArgonBox>
                        <ArgonBox mt={3}>
                            <Card>
                                <Grid container p={1}>
                                    <Grid item xs={12} textAlign="center">
                                        <ArgonTypography variant="h2">Locators</ArgonTypography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </ArgonBox>
                    </Grid>
                </Grid>
            </ArgonBox>
            <ArgonBox mb={5}>
                <Grid container justifyContent="center">
                    {getPagesLocator()}
                </Grid>
            </ArgonBox>
            <Footer />
        </DashboardLayout>
    );
}


export default LocatorMonitoring;
