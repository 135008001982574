import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { CircularProgress, tooltipClasses } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import ArgonBox from "components/ArgonBox";
import Footer from "components/Footer";
import Header from "components/Header";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useMemo } from "react";
import useAuth from "utils/useAuth";
import { Mailing } from "../../api/BackendApi/Mailing";
import { Add } from "@mui/icons-material";
import ArgonButton from "components/ArgonButton";
import MailInputDialog from "./components/MailInputDialog";


const loading = () => {
  return (<ArgonBox mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <CircularProgress size={64} />
  </ArgonBox>);
};

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const buttonStyleLeft = ({ functions: { pxToRem } }) => ({
  width: pxToRem(34),
  minWidth: pxToRem(34),
  height: pxToRem(34),
  minHeight: pxToRem(34),
  mr: 1
});

const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}


function MailingListMngmt() {
  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const [mailInputDialogOpen, setMailInputDialogOpen] = useState(false);

  const [refreshCounter, setRefreshCounter] = useState(0);
  const [mainMailingListId, setMainMailingListId] = useState(0);

  const refresh = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const handleCloseMailInputDialog = () => {
    refresh();
    setMailInputDialogOpen(false);
  };

  /*const handleDeleteSchedule = async (schedule_id) => {
    await Schedule.delete(schedule_id, false);
    enqueueSnackbar("Schedule unpaused", { variant: "success" });
    refresh();
  };*/

  /*const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        grow: true,
        enableEditing: false,
        visibleInShowHideMenu: false
      },
      {
        accessorKey: 'name',
        header: 'Name',
        grow: true,
      },
      {
        accessorKey: 'mails',
        header: 'Mails',
        grow: false,
      }
    ],
    [],
  );*/

  const columns = useMemo(
    () => [
      {
        accessorKey: 'mail',
        header: 'Mail',
        grow: true,
      }
    ],
    [],
  );

  const [data, setData] = useState(null);

  const table = useMaterialReactTable({
    columns: columns,
    data: data ?? [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    initialState: { density: 'compact', pagination: { pageSize: 30 }, columnVisibility: { id: false } },
    enableColumnResizing: true,
    columnResizeMode: 'onEnd',
    enableDensityToggle: false,
    columnFilterDisplayMode: 'popover',
    enableEditing: false,
    editDisplayMode: 'row',
    // onEditingRowSave: handleEditSchedule,
    getRowId: (row) => row.id,  //custom row id
    positionActionsColumn: 'last',
    muiPaginationProps: {
      rowsPerPageOptions: [30, 50, 100],
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 150, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
        grow: false,
      },
    },
    muiTableHeadProps: {
      sx: {
        padding: '0 !important',
      },
    },
    muiEditTextFieldProps: {
      inputProps: {
        sx: {
          width: '100% !important',
          height: '100% !important',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: "0.8rem",
        justifyContent: 'center',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "0.8rem",
        '& .Mui-TableHeadCell-Content': {
          justifyContent: 'center',
        },
      },
    },
    muiTablePaperProps: ({ table }) => ({
      //not sx
      style: {
        zIndex: table.getState().isFullScreen ? 10000 : undefined,
      },
    }),
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),
    renderTopToolbarCustomActions: ({ table }) => {
      return <ArgonButton iconOnly variant="contained" color="primary" size="large" sx={buttonStyleLeft} onClick={() => setMailInputDialogOpen(true)}>
        <NoMaxWidthTooltip title="Add mail" placement="top">
          <Add />
        </NoMaxWidthTooltip>
      </ArgonButton>;
    }
  });

  const doWork = async () => {
    let mailingResponse = await Mailing.getAll();
    if (mailingResponse.status != 200) return; // TODO error handling
    let mainMailingList = mailingResponse.data.maling_lists[0];
    setMainMailingListId(mainMailingList._id);
    let mails = mainMailingList.mails.map((mail) => {
      return {
        mail: mail,
      };
    });
    setData(mails);
  };

  useEffect(() => {
    doWork();
  }, [refreshCounter]);

  let body;
  if (data == null) {
    body = loading();
  } else {
    body = <MaterialReactTable table={table} />
  }

  return (
    <DashboardLayout>
      <Header />
      <MailInputDialog
        open={mailInputDialogOpen}
        onClose={handleCloseMailInputDialog}
        mailingListId={mainMailingListId}
      />
      <ArgonBox mt={5} mb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11}  >
            <Card sx={{ minHeight: "calc(100vh - 30vh)", display: "flex" }}>
              <ArgonBox pt={2} pl={2} pr={2}>
                {body}
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MailingListMngmt;
