import AssignmentIcon from '@mui/icons-material/Assignment';
import StopIcon from '@mui/icons-material/Stop';
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import { Box, CircularProgress, Grid, Tooltip, tooltipClasses } from "@mui/material";

import moment from 'moment';

import { HUBAPI } from "api/BackendApi/HUB";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';

import ArgonProgress from 'components/ArgonProgress';
import AvatarWithName from 'components/AvatarWithName';
import { styled } from "@mui/material/styles";
import ArgonProgressLabel from 'components/ArgonProgress/index2';

import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});


const loading = () => {
  return (<ArgonBox mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <CircularProgress size={64} />
  </ArgonBox>);
};

const centeredText = (message) => {
  return <ArgonBox
    mt={2}
    display="flex"
    justifyContent="center"
    alignItems="center">
    <ArgonTypography textAlign="center">{message}</ArgonTypography>
  </ArgonBox>;
};

const buttonStyleLeft = ({ functions: { pxToRem } }) => ({
  width: pxToRem(34),
  minWidth: pxToRem(34),
  height: pxToRem(34),
  minHeight: pxToRem(34),
  mr: 1
});

function ProgressTable({ onOpenLogs, filters }) {

  const columns = useMemo(
    () => [
      {
        accessorKey: 'requestTime',
        header: 'Request Time',
        grow: true,
      },
      {
        accessorKey: 'startTime',
        header: 'Start Time',
        grow: true,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        grow: true,
      },
      {
        accessorKey: 'cycle',
        header: 'Cycle',
        grow: true,
      },
      {
        accessorKey: 'capability',
        header: 'Capability',
        grow: true,
      },
      {
        accessorKey: 'image',
        header: 'Image',
        grow: true,
        size: 130,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'tester',
        header: 'Tester',
        grow: true,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableColumnFilter: false,
        grow: true,
        size: 150,
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        grow: true,
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const statusColorMap = {
    "RUNNING": "info",
    "PENDING": "attention",
    "COMPLETED": "success",
    "CRASHED": "error",
    "ABORTED": "error",
    "N/A": "error"
  };

  const finalStatusMap = {
    "PASSED": "success",
    "FAILED": "error",
    "Not Completed": "attention"
  };

  const isCompletedStatus = (status) => status === "COMPLETED";

  const execStatusToPercentage = (execStatus) => {
    if (execStatus.stepsCount === 0) return 0;
    return (100 / execStatus.stepsCount * execStatus.currentStep).toFixed(2);
  };

  const execStatusToLabel = (execStatus) => {
    if (execStatus.stepsCount === 0) return "";
    return execStatus.currentStep + "/" + execStatus.stepsCount;
  }

  const mounted = useRef(false);
  const navigate = useNavigate();

  const [executions, setExecutions] = useState(null);
  const [users, setUsers] = useState([]);
  const [instances, setInstances] = useState([]);

  const [error, setError] = useState(null);

  const [data, setData] = useState([]);

  const table = useMaterialReactTable({
    columns: columns,
    data: data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    initialState: { density: 'compact', pagination: { pageSize: 30 } },
    enableColumnResizing: true,
    columnResizeMode: 'onEnd',
    enableDensityToggle: false,
    columnFilterDisplayMode: 'popover',
    muiPaginationProps: {
      rowsPerPageOptions: [30, 50, 100],
    },
    muiTableHeadProps: {
      sx: {
        padding: '0 !important',
      },
    },
    muiEditTextFieldProps: {
      inputProps: {
        sx: {
          width: '100% !important',
          height: '100% !important',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: "0.8rem",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "0.8rem",
        '& .Mui-TableHeadCell-Content': {
          justifyContent: 'center',
        },
      },
    },
    muiTablePaperProps: ({ table }) => ({
      //not sx
      style: {
        zIndex: table.getState().isFullScreen ? 10000 : undefined,
      },
    }),
    renderTopToolbarCustomActions: ({ table }) => {
      if (executions.length > 0) return <div>
        <ArgonButton iconOnly variant="contained" color="primary" size="large" sx={buttonStyleLeft} onClick={cleanVisible}>
          <NoMaxWidthTooltip title="Clear queue using visible statuses" placement="top">
            <PlaylistRemoveIcon />
          </NoMaxWidthTooltip>
        </ArgonButton>
        <ArgonButton iconOnly variant="contained" color="error" size="large" sx={buttonStyleLeft} onClick={stopAll}>
          <NoMaxWidthTooltip title="Stop all pending and running tests" placement="top">
            <StopCircleIcon />
          </NoMaxWidthTooltip>
        </ArgonButton>
      </div>;

      return <div />;
    }
  });

  const onStopTest = async (execution) => {
    // TODO loading?
    await HUBAPI.stopExecution(execution.id);
    updateTable();
  };

  const goToResults = (execution, instance) => {
    console.log(instance)
    navigate('/test-results', { state: { "instances": [instance] } });
  }

  const cleanVisible = async () => {
    try {
      await HUBAPI.cleanQueue(filters);
      updateTable();
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to clean queue", { variant: "error" })
    }
  };

  const stopAll = async () => {
    try {
      await HUBAPI.stopAll();
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to stop all tests", { variant: "error" })
    }
  };

  const updateTable = async () => {
    try {
      let resp = await HUBAPI.getExecutions();
      setUsers(resp.data.users);
      setInstances(resp.data.instances);
      setExecutions(resp.data.executions);
      setError(null);
    } catch (e) {
      setError(e);
    }
  };

  const updateLoop = async () => {
    try {
      if (!mounted.current) return;
      await updateTable();
    } finally {
      setTimeout(updateLoop, 5000);
    }
  }

  const generateTableRows = () => {
    return executions
      .filter((e) => filters.includes(e.status.toLowerCase()))
      .map((execution) => {
        let instance = instances.find((c) => c._id === execution.testinstanceID);
        let image = execution.smallCapability?.image;
        if (image == null) {
          image = <ArgonBox />;
        } else {
          image = <ArgonBox display='flex' justifyContent='center' width='2vw'><ArgonBox component="img" src={image} width="100%" /></ArgonBox>
        }

        const format1 = "YYYY-MM-DD HH:mm:ss"
        let startDate;
        if (execution.startDate === undefined || execution.startDate === 0) {
          startDate = 'Not started yet';
        } else {
          var date = new Date(execution.startDate);
          startDate = moment(date).utcOffset(0).format(format1);
        }

        let requestTime = execution.requesttime;
        if (("" + execution.requesttime).length === 15) {
          requestTime = execution.requesttime * 10;
        }

        var date = new Date(requestTime / 1000);
        var requesttimeRead = moment(date).utcOffset(0).format(format1);

        var actions = [];

        if (execution.status !== "PENDING") {
          actions.push(<ArgonButton
            key="logs"
            color="info"
            variant="text"
            onClick={() => { onOpenLogs(execution) }}
          >
            <AssignmentIcon /> Logs
          </ArgonButton>);
        }

        if (execution.status === "RUNNING" || execution.status === "PENDING") {
          actions.push(
            <ArgonButton
              key="stop"
              color="error"
              size="small"
              iconOnly
              sx={{ marginLeft: "5px" }}
              onClick={() => { onStopTest(execution) }}
            >
              <StopIcon />
            </ArgonButton>
          );
        } else {
          if (instance != null) {
            actions.push(
              <ArgonButton
                key="results"
                color="info"
                variant="text"
                onClick={() => { goToResults(execution, instance) }}
              >
                <AssignmentIcon /> Results
              </ArgonButton>
            );
          }
        }

        var isComplete = isCompletedStatus(execution.status);
        var isRunning = execution.status === "RUNNING";

        var hasStepLabel = false;
        var percentage;
        var label;

        if (execution.execStatus.stepsCount !== 0 && isRunning) {
          hasStepLabel = true;
          percentage = execStatusToPercentage(execution.execStatus);
          label = execStatusToLabel(execution.execStatus) + " (" + (percentage | 0) + "%)";
        }

        var status = <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center">
          <Grid
            container
            spacing={1}
            direction="row">
            {!isComplete && <Grid item><ArgonBadge container color={statusColorMap[execution.status.toUpperCase()] ?? "error"} variant="contained" size="md" badgeContent={execution.status} /></Grid>}
            {isComplete && <Grid item><ArgonBadge container color={finalStatusMap[execution.finalStatus.toUpperCase()] ?? "error"} variant="contained" size="md" badgeContent={execution.finalStatus} /></Grid>}
          </Grid>
          <Grid item><Box height={5}></Box></Grid>
          {hasStepLabel && <Grid item sx={{ width: "100%" }}>
            <ArgonProgressLabel /*variant="determinate"*/ label={label} value={parseFloat(percentage)} color={"success"} />
          </Grid>}
        </Grid>;


        let user = users.find((u) => u._id === execution.user.id);
        return {
          requestTime: requesttimeRead,
          startTime: startDate,
          name: execution.name,
          cycle: instance?.cycle?.name ?? "Unknown Cycle",
          // device: execution.smallCapability?.name ?? 'Selecting Device',
          capability: execution.capabilityId.split('-')[0],
          image: image,
          tester: <AvatarWithName user={user} />,
          status: status,
          actions: <div>
            {actions}
          </div>
        };
      });
  }

  useEffect(() => {
    mounted.current = true;
    updateLoop();
    return () => {
      mounted.current = false;
    }
  }, []);

  useEffect(() => {
    if (executions != null) {
      setData(generateTableRows());
    }
  }, [executions, filters]);

  let body;

  if (error != null) {
    body = centeredText("Cannot retreive current executions");
  } else if (executions == null) {
    body = loading();
  } else if (executions.length === 0) {
    body = centeredText("No Recent Executions");
  } else {
    body = <MaterialReactTable table={table} />
  }

  return body;
}

export default ProgressTable;
