import React, { useState } from "react";

// @mui material components
import {enqueueSnackbar} from "notistack";
import {Settings} from "../../../api/BackendApi/Settings";
import Grid from "@mui/material/Grid";
import ArgonSelect from "../../../components/ArgonSelect";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ArgonInput from "../../../components/ArgonInput";
import FormField from "../../user-profile/components/FormField";
import ArgonTypography from "../../../components/ArgonTypography";

function FeatureCard({setting}) {
    const [selected, setSelected] = useState(setting.selected ? setting.selected : null);
    const [active, setActive] = useState(setting.enabled ? setting.enabled : false);
    const [name, setName] = useState(setting.setting);

    const optionsAppLocation = [
        { label: "SauceLabs", value: "SauceLabs" },
    ];

    const handleSelectSetting = async (event) => {
        try {

            await Settings.updateSettingSelected(setting._id, event ? event.value : null);
            setSelected(event ? event.value : null);
            enqueueSnackbar('Setting selected!',{variant:'success'});
        } catch (e) {
            console.log(e);
            enqueueSnackbar('Error during the selection of the setting',{variant:'error'})
        }
    }

    const handleEnableSetting = async () => {
        try {
            await Settings.updateSetting(setting._id, !active);
            setActive(!active);
            enqueueSnackbar(!active ? 'Setting enabled!' : 'Setting disabled!',{variant:'success'});
        } catch (e) {
            console.log(e);
            enqueueSnackbar('Error during the enabling of the setting',{variant:'error'})
        }
    }

    const renderFeature = () => {
        if(name==="App location"){
            return (
                <Grid item md={6} xs={6} pt={1} pl={3}>
                    <ArgonSelect
                        placeholder={name}
                        isClearable
                        isSearchable
                        options={optionsAppLocation}
                        defaultValue={selected ? {label: selected, value: selected} : null}
                        onChange={(e) => handleSelectSetting(e)}
                    />
                </Grid>
            );
        }else if(name==="Device Password"){
            return (
                <Grid item md={6} xs={6} pt={2} pl={3}>
                    <FormField
                        key={setting._id}
                        label={"Device password"}
                        value={selected==="" ? null : selected}
                        placeholder={"Insert password"}
                        disabled={true}
                    />
                </Grid>
            );
        }else{
            return (
                <Grid key={setting._id} item md={12} xs={12} pt={1}>
                    <FormControl component="fieldset" sx={{ ml: 1 }}>
                        <FormControlLabel
                            checked={active}
                            control={<Checkbox />}
                            label={name}
                            onChange={() => handleEnableSetting()}
                        />
                    </FormControl>
                </Grid>
            );
        }
    }

    return (
            <Grid container direction="row" p={0} >
                {renderFeature()}
            </Grid>
    );
}

export default FeatureCard;