import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Mailing } from "api/BackendApi/Mailing";

import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";

function MailInputDialog({ open, onClose, mailingListId }) {

  const [mail, setMail] = useState("");

  const addMailAndClose = async () => {
    await Mailing.addMail(mailingListId, mail);
    enqueueSnackbar("Mail added!", { variant: "success" });
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ "& .MuiDialog-paper": { width: "100%" } }
      }
      fullWidth={"md"}
      maxWidth={"md"}
    >
      <DialogTitle textAlign="center">{"Add Mail"}</DialogTitle>
      <DialogContent>
        <ArgonBox width="12rem" mr="auto">
          <ArgonInput
            placeholder="Mail..."
            value={mail}
            onChange={({ currentTarget }) => {
              setMail(currentTarget.value);
            }}
          />
        </ArgonBox>
        <ArgonButton color="primary" variant="gradient" onClick={addMailAndClose}>Add</ArgonButton>
      </DialogContent>
    </Dialog >
  );
}

export default MailInputDialog;