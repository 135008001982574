import apiClient from "api/BackendApi";

const Mailing = {
  getAll: () => apiClient.get("/be/mailing/all"),
  addMail: (mailing_list_id, mail) => apiClient.post("/be/mailing/add_mail", { mailing_list_id: mailing_list_id, mail: mail }),
}

export {
  Mailing
};
