import { useEffect, useState } from "react";
import CronJS from "react-js-cron-mui";
import cronstrue from 'cronstrue';

import ArgonTypography from "components/ArgonTypography";
import ArgonDatePicker from "components/ArgonDatePicker";
import { Divider, Grid, Switch } from "@mui/material";
import ArgonSelect from "components/ArgonSelect";

const whenSendMailOptions = [
    { label: "Always", value: "ALWAYS_SEND" },
    { label: "On Failure", value: "SEND_ON_FAILURES" }
];

const parallelizationOptions = [
    { label: "Serialize", value: "SERIALIZE_ALL" },
    { label: "Parallelize", value: "PARALLELIZE_ALL" },
];

function ScheduleConfiguration({ scheduleInfo, setScheduleInfo }) {
    const [state, setState] = useState({
        infiniteSchedule: scheduleInfo ? scheduleInfo.infiniteSchedule : true,
        startDate: scheduleInfo ? scheduleInfo.startDate : new Date(),
        endDate: scheduleInfo ? scheduleInfo.endDate : null,
        enableRecurrence: scheduleInfo ? scheduleInfo.enableRecurrence : false,
        mailMe: scheduleInfo ? scheduleInfo.mailMe : false,
        mailMailingList: scheduleInfo ? scheduleInfo.mailMailingList : false,
        selectedSendMailBehaviour: scheduleInfo ? scheduleInfo.selectedSendMailBehaviour : whenSendMailOptions[0].value,
        selectedParallelizationOption: scheduleInfo ? scheduleInfo.selectedParallelizationOption : parallelizationOptions[0].value,
        cron: scheduleInfo ?scheduleInfo.cron : "0 9 * * *",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        error: scheduleInfo ? scheduleInfo.error : null,
    });

    const handleChange = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    useEffect(() => {
        setScheduleInfo(state);
    }, [state, setScheduleInfo]);

    const lowerFirstLetter = (string) => string.charAt(0).toLowerCase() + string.slice(1);

    const getRangeBlock = () => (
        <Grid item xs>
            <ArgonTypography color="secondary">Range of recurrence</ArgonTypography>
            <Grid container direction={"row"} spacing={1}>
                <Grid item>
                    <ArgonTypography>From</ArgonTypography>
                </Grid>
                <Grid item sx={{ width: "10vw" }}>
                    <ArgonDatePicker
                        options={{
                            onChange: (dates) => handleChange('startDate', dates[0]),
                            mode: "single",
                            altInput: true,
                            dateFormat: 'Z',
                            altFormat: "Y-m-d H:i",
                            enableTime: true,
                            defaultDate: [new Date()],
                            minDate: new Date()
                        }}
                    />
                </Grid>
                {!state.infiniteSchedule && (
                    <>
                        <Grid item>
                            <ArgonTypography>to</ArgonTypography>
                        </Grid>
                        <Grid item sx={{ width: "10vw" }}>
                            <ArgonDatePicker
                                options={{
                                    onChange: (dates) => handleChange('endDate', dates[0]),
                                    mode: "single",
                                    altInput: true,
                                    dateFormat: 'Z',
                                    altFormat: "Y-m-d H:i",
                                    enableTime: true,
                                    defaultDate: [new Date()]
                                }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid container direction={"row"} spacing={0}>
                <Grid item>
                    <ArgonTypography sx={{ paddingTop: "0.4rem" }} variant={"body2"}>Never end schedule</ArgonTypography>
                </Grid>
                <Grid item ml={2}>
                    <Switch
                        checked={state.infiniteSchedule}
                        onClick={() => handleChange('infiniteSchedule', !state.infiniteSchedule)}
                    />
                </Grid>
            </Grid>
        </Grid>
    );

    const getRecurrenceBlock = () => (
        <Grid item xs>
            <ArgonTypography color="secondary">Recurrence</ArgonTypography>
            {state.enableRecurrence && (
                <>
                    <CronJS
                        value={state.cron}
                        setValue={(value) => handleChange('cron', value)}
                        onError={(err) => handleChange('error', err)}
                        defaultPeriod={"day"}
                    />
                    <ArgonTypography variant={"body2"}>Occurs {lowerFirstLetter(cronstrue.toString(state.cron, { use24HourTimeFormat: true }))}</ArgonTypography>
                </>
            )}
            <Grid container direction={"row"} spacing={0}>
                <Grid item>
                    <ArgonTypography sx={{ paddingTop: "0.4rem" }} variant={"body2"}>Enable Recurrence</ArgonTypography>
                </Grid>
                <Grid item ml={2}>
                    <Switch
                        checked={state.enableRecurrence}
                        onClick={() => handleChange('enableRecurrence', !state.enableRecurrence)}
                    />
                </Grid>
            </Grid>
        </Grid>
    );

    const getOtherSettingsBlock = () => {
        return <Grid item xs>
            <ArgonTypography color="secondary">More</ArgonTypography>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs>
                    <ArgonTypography variant={"subtitle2"} opacity={0.4}>Parallelization</ArgonTypography>
                    <ArgonSelect
                        size={"small"}
                        sx={{ overflow: "hidden" }}
                        placeholder={"Parallelization"}
                        defaultValue={state.selectedParallelizationOption}
                        onChange={(opt) => handleChange('selectedParallelizationOption', opt.value) }
                        options={parallelizationOptions}
                    />
                </Grid>
            </Grid>
        </Grid>;
    }

    const getMailingBlock = () => (
        <Grid item xs>
            <ArgonTypography color="secondary">Notifications</ArgonTypography>
            <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={3}>
                    <ArgonTypography variant={"body2"}>Mail me</ArgonTypography>
                    <Switch checked={state.mailMe} onClick={() => handleChange('mailMe', !state.mailMe)} />
                </Grid>
                <Grid item xs={3}>
                    <ArgonTypography variant={"body2"}>Mailing list</ArgonTypography>
                    <Switch checked={state.mailMailingList} onClick={() => handleChange('mailMailingList', !state.mailMailingList)} />
                </Grid>
                <Grid item xs={6}>
                    <ArgonTypography variant={"subtitle2"} opacity={0.4}>When</ArgonTypography>
                    <ArgonSelect
                        size={"small"}
                        defaultValue={state.selectedSendMailBehaviour}
                        onChange={(opt) => handleChange('selectedSendMailBehaviour', opt.value)}
                        options={whenSendMailOptions}
                    />
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <Grid container direction="column" p={5}>
            <Grid container direction="row" spacing={5}>
                {getRangeBlock()}
                {getRecurrenceBlock()}
            </Grid>
            <Divider />
            <Grid container direction="row" spacing={5}>
                {getOtherSettingsBlock()}
                {getMailingBlock()}
            </Grid>


        </Grid>
    );
}

export default ScheduleConfiguration;
