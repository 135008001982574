import apiClient from "api/BackendApi";

const Schedule = {
  getAll: () => apiClient.get("/be/schedules/all"),
  edit: (schedule_id, name, notes) => apiClient.post("/be/schedules/edit", { schedule_id: schedule_id, name: name, notes: notes }),
  pause: (schedule_id, paused) => apiClient.post("/be/schedules/pause", { schedule_id: schedule_id, paused: paused }),
  pauseFromTo: (schedule_id, fromDate, toDate) => apiClient.post("/be/schedules/pause", { schedule_id: schedule_id, pause_range: { from: fromDate, to: toDate } }),
  removePausePeriod: (schedule_id) => apiClient.post("/be/schedules/remove_from_to_pause", { schedule_id: schedule_id }),
  delete: (schedule_id) => apiClient.post("/be/schedules/delete", { schedule_id: schedule_id }),
  editTest: (schedule_id, test_id, enabled) => apiClient.post("/be/schedules/editTest", { schedule_id: schedule_id, test_id: test_id, enabled: enabled }),
}

export {
  Schedule
};
